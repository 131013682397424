<template>
  <div class="popup popup-registration popup-open modal-mask">
    <div class="modal-wrapper">
      <div class="popup-close-bloc" @click.self="$emit('close')" />
      <div class="popup-wr-item signup-form-wrapper">
        <div class="modal-header">
          <h1 class="signup-title">Create your</h1>
          <h2 class="signup-subtitle">Account</h2>
          <span class="popup-close-btn" @click.self="$emit('close')">&times;</span>
        </div>

        <div class="popup-top" :style="{ background: 'linear-gradient(0deg, #0f0f11 0%, #19191b 100%)' }">
          <form action="#" class="signup-form">
            <p v-if="getRequestStateUser('registration', 'isRejected')" class="error-message">
              {{ getRequestStateUser('registration', 'error').message }}
            </p>

            <div class="form-group">
              <label>
                <span class="visually-hidden">{{ $t('modals.sign_up.email') }}</span>
                <div class="input-wrapper">
                  <input
                    v-validate="'required|email'"
                    data-vv-scope="register"
                    v-model="newProfileForm.email"
                    :class="{ 'form-input': true, error: errors.has('register.email') }"
                    name="email"
                    type="email"
                    :placeholder="$t('modals.sign_up.email')"
                    @focus="message = ''" />
                </div>
              </label>
            </div>

            <div class="form-group">
              <label>
                <span class="visually-hidden">{{ $t('modals.sign_up.name') }}</span>
                <div class="input-wrapper">
                  <input
                    v-validate="'required|alpha_spaces|min:2|max:50'"
                    data-vv-scope="register"
                    v-model="newProfileForm.first_name"
                    :class="{ 'form-input': true, error: errors.has('register.first_name') }"
                    :placeholder="$t('modals.sign_up.your_name')"
                    name="first_name"
                    type="text"
                    @focus="message = ''" />
                </div>
              </label>
            </div>

            <div class="form-group">
              <label>
                <span class="visually-hidden">{{ $t('modals.sign_up.surname') }}</span>
                <div class="input-wrapper">
                  <input
                    v-validate="'required|alpha_spaces|min:2|max:50'"
                    data-vv-scope="register"
                    v-model="newProfileForm.last_name"
                    :class="{ 'form-input': true, error: errors.has('register.last_name') }"
                    :placeholder="$t('modals.sign_up.your_surname')"
                    name="last_name"
                    type="text"
                    @focus="message = ''" />
                </div>
              </label>
            </div>

            <div class="form-group">
              <label>
                <span class="visually-hidden">{{ $t('modals.sign_up.password') }}</span>
                <div class="input-wrapper">
                  <input
                    ref="password"
                    v-validate="'required|min:6'"
                    data-vv-scope="register"
                    v-model="newProfileForm.password"
                    :class="{ 'form-input': true, error: errors.has('register.password') }"
                    name="password"
                    type="password"
                    :placeholder="$t('modals.sign_up.password')"
                    @focus="message = ''" />
                </div>
              </label>
            </div>

            <div class="form-group">
              <label>
                <span class="visually-hidden">{{ $t('modals.sign_up.repeat') }}</span>
                <div class="input-wrapper">
                  <input
                    v-validate="'required|confirmed:password'"
                    data-vv-as="password"
                    data-vv-scope="register"
                    :class="{ 'form-input': true, error: errors.has('register.password_confirmation') }"
                    v-model="repeatPassword"
                    type="password"
                    name="password_confirmation"
                    :placeholder="$t('modals.sign_up.repeat')"
                    @focus="message = ''" />
                </div>
              </label>
            </div>

            <div v-if="captchaSettings.sitekey && captchaSettings.module === 'recaptcha_v2'" class="captcha-wrapper">
              <recaptcha
                ref="recaptcha"
                :sitekey="captchaSettings.sitekey"
                :load-recaptcha-script="true"
                :language="$root.$i18n.locale"
                @verify="(token) => (newProfileForm.captcha = token)" />
            </div>

            <button type="button" class="signup-button" @click.prevent="registration" tabindex="0">
              <span class="sign-up-span">{{ $t('ui.buttons.sign_up') }}</span>
            </button>

            <p class="terms-text">
              {{ $t('modals.sign_up.rules') }}
            </p>

            <div class="div-for-line"></div>

            <div class="sign-in-wrapper">
              <a
                @click="
                  showLog(true)
                  showReg(false)
                "
                class="signin-link"
                tabindex="0"
                >Sign In</a
              >
            </div>
          </form>
        </div>

        <template v-if="getRequestStateSocial('getSocials', 'isSuccess') && socials.data.length > 0">
          <nav class="auth-nav">
            <a @click="$emit('switchToLogin')" class="signin-link" tabindex="0">Sign In</a>
          </nav>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  data() {
    return {
      message: '',
      repeatPassword: '',
      newProfileForm: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        captcha: '',
        code: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      captchaSettings: 'params/getCaptchaConf',
      socials: 'social/getSocials',
      getRequestStateSocial: 'social/getRequestState',
      getRequestStateUser: 'user/getRequestState'
    })
  },
  mounted() {
    return this.updateSocials()
  },
  methods: {
    ...mapActions({
      updateSocials: 'social/updateSocials',
      createUser: 'user/reg'
    }),
    ...mapMutations({
      showLog: 'user/login',
      showReg: 'user/reg'
    }),
    async registration() {
      if (!(await this.$validator.validateAll('register'))) return
      if (!this.newProfileForm.captcha && this.captchaSettings.sitekey && this.captchaSettings.module) {
        this.$swal('', this.$t('errorMessage.1601588666628861'), 'error')
        return
      }
      await this.createUser({
        ...this.newProfileForm,
        return_to: this.$rest.baseUrl + this.localePath('/') + '{status}/confirm/account/'
      })
      if (this.getRequestStateUser('registration', 'error').errorCode === 1601588666628861) {
        if (this.$refs.recaptcha && this.$refs.recaptcha.reset) this.$refs.recaptcha.reset()
      }
    }
  }
}
</script>

<style scoped>
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.signup-form-wrapper {
  width: 471px;
  max-width: 100%;
  border-radius: 9px;
  background: linear-gradient(0deg, #0f0f11 0%, #19191b 100%);

  color: #fff;
  font: 300 18px Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  width: 90%;
  justify-content: space-around;
  margin-top: 40px;

  .popup-label {
    margin-bottom: 0;
  }
}

.signup-title {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 30px;
  letter-spacing: 4.5px;
  text-align: center;
  font-weight: 300;
  margin: 0;
}

.signup-subtitle {
  color: #00b2ff;
  text-shadow: 0 0 50px #00b2ff;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 4.5px;
  text-align: center;
  margin: 15px 0 0;
}

.form-group {
  margin-top: 25px;
  width: 100%;
}

.input-wrapper {
  position: relative;
  width: 100%;
}

.input-icon {
  position: absolute;
  left: 24px;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.form-input {
  width: 100%;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background: #0f0f11;
  /* padding: 24px 24px 24px 65px; */
  color: #fff;
  border: 1px solid #0f0f11;
  font-size: 18px;
}

.form-input.error {
  border: 1px solid #ff0000;
}

.signup-button {
  border-radius: 10px;
  background: #00b2ff;
  margin: 40px auto 0;
  width: 276px;
  max-width: 100%;
  color: #151515;
  font-weight: 700;
  padding: 24px 47px;
  border: none;
  cursor: pointer;
  display: block;
}

.terms-text {
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
  margin: 33px auto 0;
  width: 300px;
  color: rgba(255, 255, 255, 0.8);
}

.divider {
  margin: 33px 0;
  height: 1px;
  background: #00b2ff;
}

.signin-link {
  color: #00b2ff;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: 400;
  letter-spacing: 2.7px;
  text-align: center;
  text-decoration: none;
  display: block;
  margin: 40px auto 0;
  cursor: pointer;
}

.error-message {
  color: red;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
}

.captcha-wrapper {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.div-for-line {
  width: 100%;
  border: 1px solid #00a7f8;
  margin-top: 30px;
  margin-bottom: 30px;
}

.sign-in-wrapper {
  margin-top: 30px;
  text-align: center;
  a {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.09px;
    letter-spacing: 0.15em;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #00a7f8;
  }
}

.sign-up-span {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.09px;
  text-decoration-skip-ink: none;
  color: #0f0f11;
}

.popup {
  label {
    margin-bottom: 0px;
  }
}

@media (min-width: 520px) {
  .signup-form-wrapper {
    width: 80%;
  }
  .sign-up-span {
    font-size: 18px;
    font-weight: 700;
    line-height: 21.09px;
    text-align: left;
    text-decoration-skip-ink: none;
  }
}

@media (min-width: 720px) {
  .signup-form-wrapper {
    width: 75%;
  }

  .signup-form {
    width: 85%;
    margin: 0 auto;
  }
}

@media (min-width: 820px) {
  .signup-form-wrapper {
    width: 75%;
  }

  .signup-form {
    width: 80%;
  }
}

@media (min-width: 990px) {
  .signup-form-wrapper {
    width: 60%;
  }

  .signup-form {
    width: 75%;
  }
}

@media (min-width: 1200px) {
  .signup-form-wrapper {
    width: 50%;
  }

  .signup-form {
    width: 70%;
  }
}

@media (min-width: 1400px) {
  .signup-form-wrapper {
    width: 36%;
  }

  .signup-form {
    width: 72%;
  }
}

@media (max-width: 991px) {
  .signup-form-wrapper {
    padding: 20px;
  }

  .form-input {
    /* padding: 24px 20px 24px 55px; */
  }

  .input-icon {
    left: 20px;
    width: 18px;
  }

  .signup-button {
    padding: 24px 20px;
  }
}
</style>
