export const scrollToSectionMixin = {
  methods: {
    async scrollToSection(sectionId, headerOffset = 80) {
      const currentRoute = this.$route.name
      const isMainPage = currentRoute === `index___${this.$i18n.locale}`

      if (!isMainPage) {
        // If we're not on the main page, navigate first
        const path = this.getTargetRoute(sectionId)
        await this.$router.push(`/${path.name}/`)
      }

      // Wait for next tick to ensure DOM is updated
      this.$nextTick(() => {
        setTimeout(() => {
          const targetSection = document.getElementById(sectionId)
          if (targetSection) {
            // Close menu if it exists
            if (this.$store?.getters['menu/menu']) {
              this.$store.commit('menu/menu', false)
            }

            const elementPosition = targetSection.getBoundingClientRect().top
            const offsetPosition = elementPosition + window.scrollY - headerOffset

            window.scrollTo({
              top: offsetPosition,
              behavior: 'smooth'
            })
          }
        }, 100) // Small delay to ensure page transition is complete
      })
    },

    checkAndScrollToHashSection(headerOffset = 80) {
      const hash = window.location.hash.slice(1)
      if (hash) {
        this.scrollToSection(hash, headerOffset)
      }
    },

    getTargetRoute(section) {
      const routeMap = {
        'faq-section': 'faq',
        'policy-section': 'aml-policy',
        'review-section': 'reviews',
        'exchange-section': this.$i18n.locale
      }

      return { name: routeMap[section] || this.$i18n.locale }
    }
  }
}
